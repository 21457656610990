import React, {
  memo, useEffect, useRef, useState, useContext,
} from 'react';
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'react-responsive';
import { useDebouncedCallback } from 'use-debounce';
import { DayPickerSingleDateController } from 'react-dates';
import 'react-dates/initialize';
import moment from 'moment';

import 'react-dates/lib/css/_datepicker.css';

import { theme } from '@tourop/config/theme';

import { DATE_DISPLAY_FORMAT, DATE_REQUEST_FORMAT } from '@ess/constants/api';

import { manualProvidedDateValidator } from '@ess/utils';

import usePopperPositioning from '@ess/hooks/usePopperPositioning';
import useOnClickOutside from '@ess/hooks/useOnClickOutside';

import Modal from '@ess/ui/Modal';
import TextInput from '@ess/ui/Form/TextInput';
import FlexBox from '@ess/ui/FlexBox';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import { useToggle } from 'react-use';
import { NextBtn, PrevBtn } from './ReplacedComponents/NavigationButtons';

export type DatePickerProps = {
  value: any;
  dateId?: string;
  placeholder: string;
  onCalendarChange?: (values: any) => void;
  numberOfMonths?: number;
  onChange?: (value: any) => void;
  isClearable?: boolean;
  onClose?: () => void;
  appendTo?: Element | null;
};
const getDisplayDateFormat = (date: string) => {
  const displayDateFormat = moment(date, DATE_REQUEST_FORMAT, true);

  return moment(displayDateFormat).isValid()
    ? displayDateFormat.format(DATE_DISPLAY_FORMAT)
    : '';
};

const DatePicker = ({
  value,
  placeholder,
  dateId,
  numberOfMonths = 1,
  onCalendarChange = undefined,
  onClose = undefined,
  appendTo = undefined,
  isClearable = false,
  onChange = undefined,
}: DatePickerProps) => {
  const inputsContainer = useRef<HTMLDivElement>();
  const [isCalendarOpen, toggleCalendar] = useToggle(false);
  const [calendarKey, setCalendarKey] = useState(nanoid());
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(null);
  const [isCalendarChange, setIsCalendarChange] = useState(true);
  const { state: SFContext } = useContext(AppConfigContext);
  const { language } = SFContext;
  const mobile = useMediaQuery({ minWidth: 0, maxWidth: 768 });

  const { styles, attributes } = usePopperPositioning({
    targetElementRef: inputsContainer,
    popperElementRef: popperElement,
    zIndex: theme.zIndex.modal,
    padding: 15,
  });

  moment.locale(language);

  const onInputClickHandler = () => {
    toggleCalendar();
  };

  const onClearHandler = () => {
    if (onChange) {
      onChange('');
    }
  };

  /**
   * Start date input change handler.
   * @param event
   */
  const onChangeHandler = useDebouncedCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const value = event.target.value;
      const date = manualProvidedDateValidator({ date: value });

      setIsCalendarChange(false);

      if (onChange) {
        onChange(date);
      }
    },
    100,
  );

  /**
   * Calendar dates change handler.
   * @param values
   */
  const onDatesChange = (values: any) => {
    setIsCalendarChange(true);
    toggleCalendar(false);

    if (onCalendarChange) {
      onCalendarChange(values);
    }
  };

  /**
   * Close calendar handler.
   */
  const closeCalendar = () => {
    toggleCalendar(false);

    if (onClose) {
      onClose();
    }
  };

  const dateInput = () => (
    <TextInput
      ref={setInputElement as any}
      name={dateId}
      maxLength={10}
      placeholder={placeholder}
      readOnly
      onClick={onInputClickHandler}
      onClear={onClearHandler}
      value={getDisplayDateFormat(value)}
      isClearable={isClearable}
      onChange={onChangeHandler}
    />
  );

  useOnClickOutside(popperElement, closeCalendar);

  useEffect(() => {
    if (!isCalendarChange && getDisplayDateFormat(value)) {
      setCalendarKey(nanoid());
    }
  }, [value, isCalendarChange]);

  return (
    <>
      <FlexBox width="100%" ref={inputsContainer as any}>
        {dateInput()}
      </FlexBox>
      <Modal
        ref={setPopperElement as any}
        width="fit-content"
        showOverlay={mobile}
        theme="white"
        showCloseIcon={false}
        title={placeholder}
        isOpen={isCalendarOpen}
        onClose={closeCalendar}
        positionedByPopper={!mobile}
        appendTo={appendTo}
        {...(!mobile ? { ...attributes.popper } : {})}
        style={{ width: 'auto', ...(!mobile ? { ...styles.popper } : {}) }}
      >
        <FlexBox flexDirection="column">
          <DayPickerSingleDateController
            key={calendarKey}
            date={value}
            focused={false}
            onFocusChange={() => false}
            calendarInfoPosition="bottom"
            renderNavNextButton={(props) => <NextBtn {...props} />}
            renderNavPrevButton={(props) => <PrevBtn {...props} />}
            onDateChange={onDatesChange}
            initialVisibleMonth={() => (value || moment())}
            hideKeyboardShortcutsPanel
            numberOfMonths={numberOfMonths}
            isOutsideRange={(day) => day.isBefore(moment())}
          />
        </FlexBox>
      </Modal>
    </>
  );
};

export default memo(DatePicker);
