import { Styled } from '@tourop/components/OfferList/Offer/MultiRoomPreview/MultiRoomPreview.styles';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { faBedFront, faForkKnife } from '@fortawesome/pro-light-svg-icons';
import {
  has,
} from 'lodash-es';
import { ISelectedRoom } from '@tourop/components/MultiRoom/types';
import Tooltip from '@ess/ui/Tooltip';
import RoomDetailOptionHolder from '@tourop/components/OfferList/Offer/MultiRoomPreview/RoomDetailOptionHolder';
import { useTranslation } from 'react-i18next';
import { OfferAttributesTagsFunc } from '@tourop/components/OfferElements/OfferAttributes/OfferAttributesTags';

type RoomDetailProps = {
  onChange?: (legacyHash: string, groupName: string, newOffer: ISelectedRoom) => void
  isLoading?: boolean
  spaceSavingMode: boolean,
  detailsWidth: number,
  item: any
  name: string
  roomName: string
  serviceName: string
  availableOffers: any
  sectionName: string
}

const getOffersListForRoomType = (availableOffers: any, parentOption: any, participants: any) => {
  const checkedServiceOffers = availableOffers.filter((childOffer: any) => {
    if (childOffer?.Offer?.Accommodation?.Service?.Name !== parentOption.offer?.Accommodation?.Service?.Name) {
      return false;
    }

    return true;
  });
  return checkedServiceOffers.filter((item: any) => {
    const participantsConfig = participants?.pax;
    if (participantsConfig.Adult.value + participantsConfig.Child.value > item.Pax.Max) {
      return false;
    }
    if (item.Adult.Max < participantsConfig.Adult.value) {
      return false;
    }
    if (item.Child.Max < participantsConfig.Child.value) {
      return false;
    }
    return true;
  });
};

export const getRoomOptionList = (
  availableOffers: any,
  sectionName: string,
  roomName: string,
  parentOption: any,
  participants: any,
  t: any,
) => {
  const allOffers = availableOffers;
  if (availableOffers.length && participants) {
    if (sectionName === 'roomName') {
      const OffersWithSameService = getOffersListForRoomType(allOffers, parentOption, participants);
      return OffersWithSameService?.map((item: any) => {
        if (item?.Offer?.Accommodation?.Room?.Id === parentOption?.offer?.Accommodation?.Room?.Id) {
          return ({
            extra: {
              isReadyAttribute: true,
              attributes: OfferAttributesTagsFunc(t, parentOption?.offer)
                .filter((attribute: any) => attribute.visible).map((attribute: any) => attribute.label),
            },
            label: `${parentOption?.offer?.Accommodation?.Room?.Name} (${parentOption?.offer?.Accommodation?.Room?.Id})`,
            roomId: parentOption?.offer?.Accommodation?.Room?.Id,
            value: { Offer: parentOption?.offer },

          }
          );
        }
        return {
          extra: {
            isReadyAttribute: true,
            attributes: OfferAttributesTagsFunc(t, item.Offer)
              .filter((attribute: any) => attribute.visible).map((attribute: any) => attribute.label),
          },
          label: `${item?.Offer?.Accommodation?.Room?.Name} (${item?.Offer?.Accommodation?.Room?.Id})`,
          roomId: item?.Offer?.Accommodation?.Room?.Id,
          value: item,
        };
      });
    }
    if (sectionName === 'roomService') {
      const roomTypeOffers = allOffers.length
        ? allOffers?.filter((item: any) => item?.Offer.Accommodation.Room.Id === parentOption.offer.Accommodation.Room.Id) : [];
      return roomTypeOffers?.map((item: any) => {
        if (item?.Offer?.Accommodation?.Service?.Name === parentOption.offer?.Accommodation?.Service?.Name) {
          return {
            extra: {
              isReadyAttribute: true,
              attributes: OfferAttributesTagsFunc(t, parentOption?.offer)
                .filter((attribute: any) => attribute.visible).map((attribute: any) => attribute.label),
            },
            label: `${parentOption?.offer?.Accommodation?.Service?.Name} (${parentOption?.offer?.Accommodation?.Service?.Id})`,
            roomId: parentOption?.offer?.Accommodation?.Room?.Id,
            value: { Offer: parentOption?.offer },

          };
        }
        return {
          extra: {
            isReadyAttribute: true,
            attributes: OfferAttributesTagsFunc(t, item.Offer)
              .filter((attribute: any) => attribute.visible).map((attribute: any) => attribute.label),
          },
          label: `${item?.Offer.Accommodation.Service.Name} (${item?.Offer.Accommodation.Service.Id})`,
          roomId: item?.Offer?.Accommodation?.Room?.Id,
          value: item,
        };
      });
    }
  }
  return [];
};

const getDetailConfig = (detailsWidth: number, sectionName: string, values: { serviceName: string, roomName: string }): { maxWidth: string, showToolTip: boolean } => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d') as any;
  ctx.font = '13px Arial bold';
  ctx.lineHeight = '15px';
  const openIconWidth = 16;
  const typeIconWidth = 19;
  // 1.18 is error in canvas per letter
  const canvasErrorPerLetter = 1.18;
  const serviceNameTextWidth = ctx.measureText(values.serviceName).width + openIconWidth + typeIconWidth + (canvasErrorPerLetter * values.serviceName.length);
  const roomNameTextWidth = ctx.measureText(values.roomName).width + openIconWidth + typeIconWidth + (canvasErrorPerLetter * values.roomName.length);
  const fixedDetailsWidth = detailsWidth - 10;

  if (roomNameTextWidth + serviceNameTextWidth <= fixedDetailsWidth) {
    return {
      maxWidth: 'unset',
      showToolTip: false,
    };
  }

  if (sectionName === 'roomService') {
    if (serviceNameTextWidth > 110) {
      return {
        maxWidth: '110px',
        showToolTip: true,
      };
    }
    return {
      maxWidth: `${serviceNameTextWidth}px`,
      showToolTip: false,
    };
  }
  if (sectionName === 'roomName') {
    if (serviceNameTextWidth > 110) {
      return {
        maxWidth: `${fixedDetailsWidth - 110}px`,
        showToolTip: true,
      };
    }
    return {
      maxWidth: `${fixedDetailsWidth - serviceNameTextWidth}px`,
      showToolTip: true,
    };
  }
  return {
    maxWidth: 'unset',
    showToolTip: false,
  };
};

const sectionIcons: any = {
  roomService: faForkKnife,
  roomName: faBedFront,
};

export const sectionHolderTitle: any = {
  roomName: 'lbl_mrf_room_name_options',
  roomService: 'lbl_mrf_service_options',
  groupName: 'lbl_mrf_group_name',
};

const RoomDetail = (
  {
    isLoading = false,
    item,
    roomName,
    serviceName,
    name,
    detailsWidth,
    spaceSavingMode,
    sectionName,
    availableOffers,
    onChange = undefined,
  }: RoomDetailProps,
) => {
  const [roomDetailsControl, setRoomDetailsControl] = useState({ maxWidth: 'unset', showToolTip: false });
  const [isOpen, setIsOpen] = useState(false);
  const elementRef = useRef<any>();
  const { t } = useTranslation();

  const optionList = useMemo(
    () => getRoomOptionList(availableOffers, sectionName, roomName, item, item?.participants, t),
    [availableOffers, roomName, item.offer, item?.participants?.total]);

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = (event: any) => {
    event.stopPropagation();
    setIsOpen(true);
  };

  const onItemClick = (value: any) => {
    if (item.offer.Base?.OfferId !== value?.Offer.Base?.OfferId) {
      const selectedFullOffer = availableOffers
        ?.filter((item: any) => item?.Offer.Base?.OfferId === value?.Offer.Base?.OfferId)[0];
      if (onChange) {
        onChange(item.offerId, item.group.description, selectedFullOffer.Offer);
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    if (detailsWidth !== 0 && spaceSavingMode) {
      setRoomDetailsControl((state: any) => ({
        ...state,
        ...getDetailConfig(detailsWidth, sectionName, { serviceName, roomName }),
      }));
    }
  }, [detailsWidth, spaceSavingMode, item]);

  const content = spaceSavingMode ? (
    <Styled.Space__Saving__Holder
      ref={elementRef}
      onClick={onOpen}
      dataMaxWidth={roomDetailsControl?.maxWidth}
    >
      {has(sectionIcons, sectionName) && (
        <Styled.Room__Details__Icon
          icon={sectionIcons[sectionName]}
        />
      )}
      <Tooltip
        disabled={!roomDetailsControl?.showToolTip}
        content={name}
      >
        <>
          <Styled.Space__Saving__Text>
            {`${name}`}
          </Styled.Space__Saving__Text>
          <Styled.Room__Detail__Chevron__Icon
            icon={faChevronDown}
          />
        </>
      </Tooltip>
    </Styled.Space__Saving__Holder>
  ) : (
    <Styled.Room__Detail__Holder
      ref={elementRef}
      onClick={onOpen}
      as="span"
    >
      {has(sectionIcons, sectionName) && (
        <Styled.Room__Details__Icon
          icon={sectionIcons[sectionName]}
        />
      )}
      <Styled.Room__Detail__Text as="span">
        {`${name}`}
        <Styled.Room__Detail__Chevron__Icon
          icon={faChevronDown}
        />
      </Styled.Room__Detail__Text>
    </Styled.Room__Detail__Holder>
  );

  return (
    <>
      {content}
      <RoomDetailOptionHolder
        isLoading={isLoading}
        elementRef={elementRef}
        isOpen={isOpen}
        onClose={onClose}
        selectedOfferId={item?.offer?.Base?.OfferId}
        onItemClick={onItemClick}
        title={t(sectionHolderTitle[sectionName])}
        options={optionList}
      />
    </>
  );
};

export default RoomDetail;
