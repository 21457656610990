import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { useReactBasket } from '@basket/hooks/useReactBasket';

import FlexBox from '@ess/ui/FlexBox';
import Tooltip from '@ess/ui/Tooltip';

import CreateBasketModal from 'apps/basket/src/components/CreateBasketModal';

import { isMobileOnly } from 'react-device-detect';
import Checkbox from '@ess/ui/Form/Checkbox';
import Text from '@ess/ui/Text';
import BasketActions from '../BasketActions';

import { Styled } from './Header.styles';

const Header = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { t } = useTranslation();
  const {
    basket,
    selectAllItems,
    changeBasket,
    createBasket,
    deleteBookmark,
  } = useReactBasket();

  const onTabClickHandler = (tab: { value: number, label: string }) => {
    changeBasket(tab.value, tab.label);
  };

  const onTabCloseHandler = useCallback((event: React.MouseEvent, value: number, isCurrentBasket: boolean) => {
    event.stopPropagation();

    const currentBookmarks = basket?.bookmarks.filter((bookmark) => bookmark.value !== value);
    const findIndex = basket?.bookmarks?.findIndex((bookmark) => bookmark.value === value);
    const itemsCount = currentBookmarks.length;
    const nextBasketIndex = isCurrentBasket && findIndex === 0 ? 0 : itemsCount - 1;
    const nextBasket = itemsCount > 0 ? currentBookmarks[nextBasketIndex]?.value : null;

    deleteBookmark(value);

    if (nextBasket) {
      changeBasket(nextBasket);
    }
  }, [basket.bookmarks]);

  if (!basket.current.id && isMobileOnly) {
    return null;
  }

  return (
    <Styled.Basket__Header>
      {isOpenModal && (
        <CreateBasketModal
          onApply={createBasket}
          onClose={() => {
            setIsOpenModal(false);
          }}
        />
      )}
      {!isMobileOnly ? (
        <Styled.Basket__Tabs>
          {basket?.bookmarks?.map((bookmark: any) => {
            const { label, value } = bookmark;
            const isCurrentBasket = value === basket.current.id;

            return (
              <Tooltip key={value} content={label} placement="top">
                <Styled.Basket__Tab
                  isCurrentBasket={isCurrentBasket}
                  onClick={() => onTabClickHandler({ value, label })}
                >
                  <Styled.Basket__Name>
                    {label}
                  </Styled.Basket__Name>
                  <Styled.Close__Icon
                    onClick={(event) => onTabCloseHandler(event, value, isCurrentBasket)}
                  >
                    <FontAwesomeIcon icon={faClose}/>
                  </Styled.Close__Icon>
                </Styled.Basket__Tab>
              </Tooltip>
            );
          })}
          <Tooltip theme="bubble-wide" content={t('lbl_create_new_basket')}>
            <Styled.Basket__Tab__Add
              hasTabs={!!basket?.bookmarks?.length}
              onClick={() => {
                setIsOpenModal(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus}/>
            </Styled.Basket__Tab__Add>
          </Tooltip>
        </Styled.Basket__Tabs>
      ) : (
        <>
          <FlexBox ml="8px" width={150}>
            <Checkbox
              checked={basket.allSelected}
              onChange={selectAllItems}
              label={(
                <Text color="darkGray">
                  {basket.allSelected ? t('lbl_uncheck_all') : t('lbl_check_all')}
                </Text>
              )}
            />
          </FlexBox>
        </>
      )}
      <FlexBox ml="auto" alignItems="center">
        <BasketActions
          basket={{
            id: basket.current.id,
            name: basket.current.name,
            description: basket.description,
            selectedItems: basket.selectedItems,
            blocked: basket.isBlocked,
          }}
        />
      </FlexBox>
    </Styled.Basket__Header>
  );
};

export default Header;
