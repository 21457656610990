import { isMobileOnly } from 'react-device-detect';
import Drawer from '@ess/ui/Drawer';
import React, { useState } from 'react';
import { Modal } from '@ess/ui/Modal/ModalsTypes';
import { useTranslation } from 'react-i18next';
import usePopperPositioning from '@ess/hooks/usePopperPositioning';
import { theme } from '@tourop/config/theme';
import useOnClickOutside from '@ess/hooks/useOnClickOutside';
import { CustomOption } from '@ess/components/OfferDetailsView/components/Filters/Field/CustomOption';
import FlexBox from '@ess/ui/FlexBox';
import { Option } from '@ess/ui/ModernSelect/Option';
import Text from '@ess/ui/Text';
import Loader from '@ess/ui/Loader';
import {
  MultiRoomCustomOption,
} from '@ess/components/OfferDetailsView/components/Filters/Field/CustomOption/CustomOption';

type RoomDetailOptionHolderProps = {
  isLoading?: boolean
  isOpen: boolean
  onClose: () => void
  selectedOfferId: string
  onItemClick: (offer: any) => void
  title: string
  elementRef: any
  options: any[]
  customContent?: React.ReactElement | null
  customControls?: React.ReactElement | undefined
}

const RoomDetailOptionHolder = (
  {
    isOpen,
    onClose,
    selectedOfferId,
    onItemClick,
    title,
    elementRef,
    options,
    isLoading = false,
    customContent = null,
    customControls = undefined,
  }: RoomDetailOptionHolderProps,
) => {
  const { t } = useTranslation();

  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopperPositioning({
    targetElementRef: elementRef,
    popperElementRef: popperElement,
    applyMaxSizeEnabled: true,
    zIndex: theme.zIndex.modal,
    padding: 15,
  });

  const onItemClickHandler = (data: any) => {
    onItemClick(data.value);
  };

  useOnClickOutside(popperElement, onClose);

  const renderOption = (data: any) => (
    <MultiRoomCustomOption data={data} selected={false}/>
  );

  const content = (
    <FlexBox
      p="small"
      flexDirection="column"
      style={{
        gap: '10px',
      }}
    >
      {options.map((item: any) => (
        <Option
          key={item.value}
          data={item}
          isSelected={item.value?.Offer?.Base?.OfferId === selectedOfferId}
          renderOption={renderOption}
          onClick={onItemClickHandler}
        />
      ))}
    </FlexBox>
  );

  const loadingHolder = (
    <FlexBox
      position="absolute"
      top="0px"
      bottom="0px"
      left="0px"
      flexWrap="nowrap"
      right="0px"
      backgroundColor="rgba(255,255,255,0.6)"
      flexDirection="column"
    >
      <Text
        fontSize="18px"
        fontWeight="bold"
        margin="auto auto 20px auto"
      >
        {t('lbl_updating_online_offer')}
      </Text>
      <Loader type="dots" margin="0 auto auto  auto"/>
    </FlexBox>
  );

  return isMobileOnly ? (
    <Drawer
      position="bottom"
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      controls={customControls}
    >
      <FlexBox
        position="relative"
        flexDirection="column"
      >
        {customContent ?? content}
        {isLoading && loadingHolder}
      </FlexBox>
    </Drawer>
  ) : (
    <Modal
      showOverlay={false}
      width={400}
      ref={setPopperElement as any}
      title={title}
      onClose={onClose}
      isOpen={isOpen}
      style={styles.popper}
      controls={customControls}
      {...attributes.popper}
    >
      <FlexBox
        position="relative"
        flexDirection="column"
      >
        {customContent ?? content}
        {isLoading && loadingHolder}
      </FlexBox>
    </Modal>
  );
};

export default RoomDetailOptionHolder;
