import FlexBox from '@ess/ui/FlexBox';
import React, {
  memo, useContext, useEffect, useRef,
} from 'react';
import Text from '@ess/ui/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { includes, isEmpty, toNumber } from 'lodash-es';
import {
  faCar, faGripDotsVertical, faHospital, faParking, faUser,
} from '@fortawesome/pro-solid-svg-icons';

import { OfferAttributesTags } from '@tourop/components/OfferElements/OfferAttributes';
import { OfferAttributesTagsFunc } from '@tourop/components/OfferElements/OfferAttributes/OfferAttributesTags';

import { AppConfigContext } from '@ess/context/AppConfigContext';
import {
  BUS_PACKAGE,
  CHECK_TYPE_FUNC,
  FLIGHT_ONLY,
  FLIGHT_PACKAGE,
  HOTEL_ONLY,
  INSURANCE,
  PARKING,
  TRIP_ROUND_TRIP,
} from '@tourop/components/OfferList/Offer/CheckTypeFunc';

import PriceElement from '@tourop/components/OfferList/Offer/OfferElements/Price';
import TransportIcon from '@tourop/components/OfferList/Offer/OfferElements/Transport';
import DateWithDuration from '@tourop/components/OfferList/Offer/OfferElements/DateWithDuration';
import HotelCategory from '@tourop/components/OfferList/Offer/OfferElements/HotelCategory';
import ServiceElement from '@tourop/components/OfferList/Offer/OfferElements/ServiceElement';

import useContentWindow, { getMandatoryOfferOnly } from '@ess/hooks/useContentWindow';
import useElementSize from '@ess/hooks/useElementSize';

import Checkbox from '@ess/ui/Form/Checkbox';
import Tooltip from '@ess/ui/Tooltip';
import Chip from '@ess/ui/Chip';

import { BasketItem } from '@basket/types';
import { SortableItemContext } from '@basket/containers/SortableContainer';

import { OfferStatus } from '@tourop/components/OfferElements';

import {
  getOfferHashGetter,
  getOfferUrl,
  getParticipantsFromHash,
  getTFG,
  getUrlSearchParams,
  getValidParticipants,
  objectToURLParams,
} from '@ess/utils';

import Anchor from '@ess/ui/Anchor';

import { useReactBasketItem } from 'apps/basket/src/hooks/useBasketItem';

import { ImageSlider } from '@ess/components/ImageSlider';
import { OfferIcon } from '@tourop/components/OfferElements/OfferIcon';
import { BasketOfferActions } from '@tourop/components/OfferElements/BasketOfferActions';
import { SupplementaryHotel } from '@tourop/components/OfferElements/SupplementaryHotel';
import { BookingId, BookingStatus } from '@tourop/components/OfferElements/Booking';
import { Media } from '@tourop/components/OfferElements/Media';

import OfferDetails from '@tourop/components/OfferList/Offer/OfferDetails';
import { UNGROUPED_VIEW } from '@ess/constants/search';

import Box from '@ess/ui/Box';

import { ImagesSizes } from '@ess/hooks/useFetchImages';
import { isMobileOnly } from 'react-device-detect';
import { Styled } from './ModernOffer.styles';

type ModernOfferProps = {
  onClick?: (offerID: string, rowIndex: number) => void;
  basketItem: BasketItem;
  isExpanded?: boolean;
  rowIndex?: number;
  isSelected?: boolean;
  basketPosition?: string;
  onCloseBasket?: () => void;
  isBasketPinned?: boolean;
  onSelect?: (rowId: number) => void;
};

const ModernOffer = memo(({
  isBasketPinned = false,
  onCloseBasket = () => false,
  onClick = () => false,
  rowIndex = 0,
  basketItem,
  isSelected = false,
  onSelect = undefined,
  isExpanded = true,
  basketPosition = '',
}: ModernOfferProps) => {
  const { t } = useTranslation();
  const { item, checkOnline, checkBookingStatus } = useReactBasketItem({
    rowId: basketItem.rowid,
  });
  const searchForm = {
    type: basketItem?.search?.form ?? '',
    hash: basketItem?.search?.hash ?? '',
  };

  const { hash: sfProtoHash, type } = searchForm;

  const offer = basketItem.offer as any;

  const { openContentWindow } = useContentWindow(type);

  const { attributes, listeners, ref } = useContext(SortableItemContext);

  const { state: AppConfig } = useContext(AppConfigContext);

  const { offerAttributesPriority, configOper } = AppConfig;

  const tfg = getTFG(offer.Base?.Price as any);

  const participants = getParticipantsFromHash(
    offer?.Base?.OfferId?.split('|')[2] as string,
  );

  const validParticipants = getValidParticipants(participants);

  const offerHashGetter = getOfferHashGetter(offer, validParticipants);

  const offerContainer = useRef<HTMLElement>(null);

  const inputContainer = useRef<HTMLElement>(null);

  const {
    size: { width },
    forceUpdate,
  } = useElementSize(inputContainer);

  const tripOffers = [
    FLIGHT_PACKAGE,
    FLIGHT_ONLY,
    BUS_PACKAGE,
    TRIP_ROUND_TRIP,
    HOTEL_ONLY,
  ];

  const getCustomChip = (offer: any) => {
    const AdditionalServices = offer?.AdditionalServices ?? [];

    if (!isEmpty(AdditionalServices)) {
      const additionalDescription = (
        <Box>
          {AdditionalServices?.map((item: any) => (item?.Assignment?.length ? (
            <Text margin="1px 0">{item.Name}</Text>
          ) : null),
          )}
        </Box>
      );

      return {
        label: t('lbl_additional_service'),
        name: 'additional-service',
        visible: true,
        tooltip: additionalDescription,
      };
    }
    return null;
  };

  const getCustomType = (item: any) => {
    if (item.offerType === 'video') {
      if (item.mediaLinkUrl.includes('youtube')) {
        return 'youtube';
      }
      if (item.mediaLinkUrl.includes('vimeo')) {
        return 'vimeo';
      }
      return item.offerType;
    }
    return item.offerType;
  };

  const isMedia = ['video', 'image'].includes(basketItem.offerType);
  const offerType = !isMedia ? CHECK_TYPE_FUNC(offer) : basketItem.offerType;

  const hasAccommodation = offer?.Accommodation;

  const offerAttributes = OfferAttributesTagsFunc(
    t,
    offer,
    offerAttributesPriority,
    true,
    getCustomChip(offer),
  );

  const {
    Base, Accommodation, Insurance, Parking,
  } = offer as any;

  const onCheckboxChangeHandler = (event: any) => {
    event.stopPropagation();

    if (onSelect) {
      onSelect(basketItem.rowid);
    }
  };

  const onStatusClickHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    checkOnline();
  };

  const onBookingStatusClickHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (basketItem.booking?.id && basketItem.offer.Base?.Operator) {
      checkBookingStatus(
        basketItem.rowid,
        basketItem.booking.id,
        basketItem.offer.Base?.Operator,
      );
    }
  };

  const openContentWindowFunction = (page: string) => {
    openContentWindow({
      offerHash: offerHashGetter(),
      offer: getMandatoryOfferOnly(offer),
      page,
    });
  };

  const onNameClick = (event: any) => {
    event.stopPropagation();
    const urlSearchParams = getUrlSearchParams({ params: ['sfr'] });
    const sfrProtoHash = urlSearchParams?.sfr ?? '';

    const params = objectToURLParams({
      so: offerHashGetter(),
      sfr: sfrProtoHash,
      sf: sfProtoHash,
    });

    const url = getOfferUrl('details', type, params);
    window.open(url, '_self');

    if (!isBasketPinned && onCloseBasket) {
      onCloseBasket();
    }
  };

  const operateOpenCloseOffer = () => {
    if (onClick) {
      onClick(offer?.Base?.OfferId ?? '', rowIndex as number);
    }
  };

  const onMiddleImageSectionClick = () => {
    openContentWindowFunction('Pictures');
  };

  const getDateForParking = (
    startValue: string | undefined,
    endValue: string | undefined,
  ) => {
    const startDate = startValue?.split(' ')[0]?.split('-')?.reverse() as any;
    startDate?.pop();
    const startTime = startValue?.split(' ')[1];
    const endDate = endValue
      ?.split(' ')[0]
      ?.split(' ')[0]
      ?.split('-')
      ?.reverse() as any;
    const endTime = endValue?.split(' ')[1];
    return `${startDate?.join('.')} ${startTime}  - ${endDate.join('.')} ${endTime}`;
  };

  const toolTipContent = () => {
    switch (offerType) {
      case PARKING:
        return (
          <FlexBox padding="2px" flexDirection="column">
            <Text fontSize="11px">
              {t('lbl_cars')}
              {': '}
              {Parking?.CarsCount}
            </Text>
            <Text fontSize="11px" margin="1px 0">
              {t('lbl_participants')}
              {': '}
              {Parking?.PersonsCount}
            </Text>
          </FlexBox>
        );
      case INSURANCE:
        return (
          <FlexBox padding="2px" flexDirection="column">
            <Text fontSize="11px">
              {t('lbl_participants')}
              {': '}
              {Insurance?.PersonsCount}
            </Text>
          </FlexBox>
        );
      default:
        return (
          <FlexBox padding="2px" flexDirection="column">
            <Text fontSize="11px">
              {t('lbl_adult')}
              {': '}
              {participants?.adultsCount}
            </Text>
            <Text fontSize="11px" margin="1px 0">
              {t('lbl_child')}
              {': '}
              {participants?.childDates?.length}
            </Text>
          </FlexBox>
        );
    }
  };

  const getOfferValues = () => {
    if (includes(tripOffers, offerType)) {
      return (
        <>
          <FlexBox mt="2px">
            <TransportIcon item={offer as any} offerHashGetter={undefined}/>
            <DateWithDuration item={offer as any} commonProps={undefined}/>
          </FlexBox>
          {hasAccommodation && (
            <FlexBox mt="2px">
              <Tooltip content={toolTipContent()}>
                <FlexBox>
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{
                      height: '12px',
                      margin: '1px 2px  0 2px',
                    }}
                  />
                  <Text
                    style={{
                      margin: '0 2px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {`${participants?.adultsCount}${!isEmpty(participants?.childDates) ? `:${participants?.childDates?.length}` : ''} / `}
                  </Text>
                </FlexBox>
              </Tooltip>
              <ServiceElement onlineOffer={offer} item={offer}/>
            </FlexBox>
          )}
        </>
      );
    }
    return <></>;
  };

  const getOfferDetails = () => {
    switch (offerType) {
      case PARKING:
        return (
          <Styled.Insurance__Details__Holder>
            <Styled.Insurance__Details__Title>
              {t('lbl_parking_details_title')}
            </Styled.Insurance__Details__Title>
            {offer?.Parking?.Description?.map((value: string) => (
              <div dangerouslySetInnerHTML={{ __html: value }}/>
            ))}
          </Styled.Insurance__Details__Holder>
        );
      case INSURANCE:
        return (
          <Styled.Insurance__Details__Holder>
            <Styled.Insurance__Details__Title>
              {t('lbl_insurance_details_title')}
            </Styled.Insurance__Details__Title>
            {offer?.Insurance?.Description?.map((value: string) => (
              <div dangerouslySetInnerHTML={{ __html: value }}/>
            ))}
          </Styled.Insurance__Details__Holder>
        );
      default:
        return (
          <FlexBox padding="0 15px">
            <OfferDetails
              searchType={searchForm.type}
              offerData={offer}
              offerHashGetter={offerHashGetter}
              isBasket
              isMultiRoomMode={false}
              onContentsChange={() => {
              }}
              viewType={UNGROUPED_VIEW}
              swapBookingEnabled={false}
            />
          </FlexBox>
        );
    }
  };

  const getOfferContent = () => {
    let anchorText: string = Base?.XCode?.Name ?? '';
    let remainingText = ` •  ${Base?.XCountry?.Name ?? ''}${
      (Base?.XRegion?.Name ?? '') && ' / '
    }${Base?.XRegion?.Name ?? ''}`;
    const offerText = `${anchorText}${remainingText}`;
    const textLength = offerText?.length ?? 0;
    const isCategoryExists = Accommodation?.Category && toNumber(Accommodation?.Category) !== 0;
    const availableWidth = isCategoryExists ? width - 28 : width;
    const totalCharactersCanFit = availableWidth / 7.5;
    let showToolTip = false;
    if (availableWidth > 0 && anchorText.length > 0) {
      showToolTip = textLength * 7.5 > availableWidth;
      if (anchorText.length > totalCharactersCanFit) {
        anchorText = `${anchorText.substring(0, totalCharactersCanFit - (isCategoryExists ? 2 : 0))}`;
        remainingText = '...';
      }
    }
    switch (offerType) {
      case PARKING:
        return (
          <FlexBox flexGrow="1">
            <FlexBox
              flexGrow="1"
              flexDirection="column"
              style={{
                cursor: 'pointer',
              }}
            >
              <FlexBox>
                <Text>
                  <Styled.Insurance__Name__Label
                    style={{
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                    }}
                  >
                    {`${Parking?.Name}`}
                  </Styled.Insurance__Name__Label>
                </Text>
              </FlexBox>
              <FlexBox>
                <FontAwesomeIcon
                  icon={faParking}
                  style={{
                    height: '15px',
                    width: '15px',
                    marginRight: '4px',
                  }}
                />
                <Styled.Insurance__Date__Label>
                  {getDateForParking(
                    Parking?.StartDateTime,
                    Parking?.EndDateTime,
                  )}
                  <span
                    style={{
                      color: '#99a1ab',
                      fontWeight: 'normal',
                    }}
                  >
                    {` (${Base?.Duration}d / ${Base?.NightsBeforeReturn}n)`}
                  </span>
                </Styled.Insurance__Date__Label>
              </FlexBox>
              <Tooltip content={toolTipContent()}>
                <FlexBox maxWidth="max-content">
                  <FontAwesomeIcon
                    icon={faCar}
                    style={{
                      marginTop: '1px',
                    }}
                  />
                  <Text as="span" margin="0 2px" fontWeight="bold">
                    {Parking.CarsCount}
                  </Text>
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{
                      marginTop: '1px',
                    }}
                  />
                  <Text as="span" margin="0 2px" fontWeight="bold">
                    {Parking.PersonsCount}
                  </Text>
                </FlexBox>
              </Tooltip>
              <FlexBox>
                <Text fontWeight="bold">
                  {!isEmpty(Base.OperatorDesc)
                    ? Base.OperatorDesc
                    : (configOper[Base.Operator]?.name as string)}
                </Text>
              </FlexBox>
            </FlexBox>
            <FlexBox minWidth="37px">
              <BasketOfferActions basketItem={basketItem}/>
            </FlexBox>
          </FlexBox>
        );
      case INSURANCE:
        return (
          <FlexBox flexGrow="1">
            <FlexBox
              flexGrow="1"
              flexDirection="column"
              style={{
                cursor: 'pointer',
              }}
            >
              <FlexBox>
                <Text>
                  <Styled.Insurance__Name__Label
                    style={{
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                    }}
                  >
                    {`${Insurance?.Name}`}
                  </Styled.Insurance__Name__Label>
                  <span>{` • ${Insurance?.DestinationLocation?.Name}`}</span>
                </Text>
              </FlexBox>
              <FlexBox>
                <FontAwesomeIcon
                  width="18px"
                  height="18px"
                  icon={faHospital}
                  style={{
                    marginRight: '4px',
                  }}
                />
                <Styled.Insurance__Date__Label>
                  {`${Insurance?.StartDate?.split('-').reverse().join('.')} -  ${Insurance?.EndDate?.split('-').reverse().join('.')}`}
                </Styled.Insurance__Date__Label>
              </FlexBox>
              <Tooltip content={toolTipContent()}>
                <FlexBox maxWidth="max-content">
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{
                      height: '12px',
                      margin: '1px 2px  0 2px',
                    }}
                  />
                  {Insurance?.PersonsCount}
                </FlexBox>
              </Tooltip>
              <FlexBox>
                <Text fontWeight="bold">
                  {!isEmpty(Base.OperatorDesc)
                    ? Base.OperatorDesc
                    : (configOper[Base.Operator]?.name as string)}
                </Text>
              </FlexBox>
            </FlexBox>
            <FlexBox minWidth="37px">
              <BasketOfferActions basketItem={basketItem}/>
            </FlexBox>
          </FlexBox>
        );
      default:
        return (
          <>
            <FlexBox width="100%" alignItems="flex-start" flexGrow={1}>
              <FlexBox
                flexDirection="column"
                flexGrow={1}
                minWidth={0}
                style={{ cursor: 'pointer' }}
              >
                <FlexBox
                  ref={inputContainer as any}
                  alignItems="flex-start"
                  mb="2px"
                >
                  <Tooltip disabled={!showToolTip} content={offerText}>
                    <Text
                      as="span"
                      mr="10px"
                      whiteSpace="noWrap"
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      <Anchor
                        fontWeight="bold"
                        color="navy"
                        onClick={onNameClick}
                      >
                        {anchorText}
                      </Anchor>
                      {remainingText}
                    </Text>
                  </Tooltip>
                  {Accommodation?.Category
                  && toNumber(Accommodation?.Category) !== 0 ? (
                    <HotelCategory Accommodation={Accommodation}/>
                    ) : null}
                </FlexBox>

                {getOfferValues()}
                <FlexBox flexDirection="column">
                  <FlexBox alignItems="center" flexWrap="wrap" mt="2px">
                    <Text fontWeight="bold" mr="small">
                      {Base?.OperatorDesc
                        ? Base.OperatorDesc
                        : Base?.Operator
                          ? configOper[Base.Operator]?.name
                          : null}
                    </Text>
                    <FlexBox mr="small">
                      <OfferAttributesTags
                        maxVisibleAttributes={1}
                        offerAttributes={offerAttributes}
                      />
                    </FlexBox>
                    {basketItem?.nameCustom && (
                      <Text as="span" fontWeight="bold">
                        {basketItem.nameCustom}
                      </Text>
                    )}
                  </FlexBox>
                </FlexBox>
              </FlexBox>

              <FlexBox
                flexShrink={0}
                flexDirection="column"
                alignItems="center"
              >
                <BasketOfferActions
                  basketItem={basketItem}
                  excludedActions={!isMobileOnly ? ['checkOffer'] : []}
                  allowedActions={
                    basketItem?.booking
                      ? [
                        'deleteOffer',
                        'goToSearchForm',
                        'editItemName',
                        'socialPostCreator',
                        'cloneOffer',
                        'deleteOffer',
                      ]
                      : []
                  }
                />
                <FlexBox
                  width={45}
                  mt="10px"
                  flexShrink={0}
                  alignItems="center"
                  flexDirection="column"
                >
                  {basketItem?.booking ? (
                    <BookingStatus
                      status={basketItem.booking.status}
                      onClick={onBookingStatusClickHandler}
                      isLoading={!!item?.online?.isLoading}
                    />
                  ) : Base?.Availability ? (
                    <>
                      <OfferStatus
                        status={Base.Availability}
                        isLoading={!!item?.online?.isLoading}
                        onClick={onStatusClickHandler}
                        startDateCheck={Base.StartDate}
                      />
                      {tfg && (
                        <Tooltip content={`${t('tfg_included')}`}>
                          <Chip variant="primary" label={t('lbl_tfg')}/>
                        </Tooltip>
                      )}
                    </>
                  ) : null}
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </>
        );
    }
  };

  useEffect(() => {
    if ((basketPosition?.length ?? 0) > 0) {
      forceUpdate();
    }
  }, [basketPosition]);

  return (
    <Styled.Modern__Offer ref={offerContainer as any}>
      <FlexBox>
        <FlexBox
          px={{
            xxs: 'small',
            xs: 'small',
            sm: 'tiny',
            md: 'tiny',
          }}
          py="small"
          alignItems="center"
          backgroundColor="#f8f8f8"
          flexDirection={{
            xxs: 'row',
            xs: 'row',
            sm: 'column',
            md: 'column',
          }}
          justifyContent="flex-start"
        >
          <Checkbox
            spacing="0px"
            checked={!!isSelected}
            onChange={onCheckboxChangeHandler}
          />

          <Tooltip
            content={t('lbl_offer_swap_disabled')}
            disabled={!attributes['aria-disabled']}
          >
            <FlexBox
              ref={ref}
              mt={{
                xxs: '0px',
                xs: '0px',
                sm: 'small',
                md: 'small',
              }}
              ml={{
                xxs: 'small',
                xs: 'small',
                sm: '0px',
                md: '0px',
              }}
              style={{ cursor: 'grab', fontSize: '18px', color: 'darkGray' }}
              {...attributes}
              {...listeners}
            >
              <FontAwesomeIcon icon={faGripDotsVertical}/>
            </FlexBox>
          </Tooltip>
        </FlexBox>

        <FlexBox position="relative" flexShrink={0}>
          {Accommodation?.Supplementary?.length && (
            <SupplementaryHotel
              width="80px"
              height="50px"
              data={Accommodation.Supplementary[0]}
            />
          )}
          {['parking', 'insurance', 'video', 'youtube', 'viemo'].includes(
            offerType as string,
          ) ? (
            <OfferIcon
              offerType={getCustomType(basketItem) as string}
              height="120px"
              width="160px"
            />
            ) : offerType === 'image' ? (
              <FlexBox width={160} height={120}>
                <img
                  src={basketItem.mediaLinkUrl}
                  alt={basketItem.nameCustom}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </FlexBox>
            ) : (
              <ImageSlider
                onClick={onMiddleImageSectionClick}
                image={Base?.ThumbUrl}
                operator={Base.Operator}
                hotelCode={offer?.Accommodation?.Code}
                imageSize={ImagesSizes.Small}
                height={120}
                width={160}
              />
            )}

          <FlexBox position="absolute" zIndex={1} top="0px" right="0px">
            {Accommodation?.ExtTripAdvisor
            && toNumber(Accommodation?.ExtTripAdvisor?.rating) !== 0 ? (
              <Tooltip content={t('tripadvisor_note')}>
                <FlexBox
                  width="45px"
                  height="25px"
                  bg="#12a180"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text
                    fontSize="12px"
                    fontWeight="bold"
                    color="white"
                    whiteSpace="nowrap"
                  >
                    {`${Accommodation.ExtTripAdvisor.rating.toString().includes('.') ? Accommodation.ExtTripAdvisor.rating : `${Accommodation.ExtTripAdvisor.rating}.0`} / 5`}
                  </Text>
                </FlexBox>
              </Tooltip>
              ) : null}
          </FlexBox>
        </FlexBox>
        <Styled.Content___Holder onClick={operateOpenCloseOffer}>
          <FlexBox
            height="100%"
            position="relative"
            flexDirection="column"
            p="7px"
          >
            {!isMedia ? (
              <>
                {getOfferContent()}
                <FlexBox height={20} alignItems="center">
                  {basketItem?.booking && (
                    <FlexBox>
                      <BookingId
                        id={basketItem.booking.id}
                        operator={basketItem.offer.Base?.Operator as string}
                      />
                    </FlexBox>
                  )}
                  {offerType !== INSURANCE ? (
                    <>
                      <PriceElement
                        rooms={undefined}
                        item={offer}
                        onlineOffer={offer}
                        status={item?.online}
                        isMultiRoomMode={false}
                        commonProps={undefined}
                        bookingDetails={basketItem.booking}
                      />
                    </>
                  ) : (
                    <PriceElement
                      rooms={undefined}
                      item={offer}
                      isMultiRoomMode={false}
                      commonProps={undefined}
                    />
                  )}
                </FlexBox>
              </>
            ) : (
              <FlexBox
                width="100%"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <FlexBox mr="small">
                  <Media data={basketItem}/>
                </FlexBox>
                <FlexBox width={45} justifyContent="center">
                  <BasketOfferActions
                    basketItem={basketItem}
                    allowedActions={['deleteOffer', 'editItemName']}
                  />
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
        </Styled.Content___Holder>
      </FlexBox>
      {!isMedia && isExpanded && <>{getOfferDetails()}</>}
    </Styled.Modern__Offer>
  );
});

export default ModernOffer;
