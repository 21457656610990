import { useTranslation } from 'react-i18next';
import FlexBox from '@ess/ui/FlexBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/pro-regular-svg-icons';
import Text from '@ess/ui/Text';
import { Button } from '@ess/ui/Button';
import React, { useState } from 'react';
import { useReactBasket } from '@basket/hooks/useReactBasket';
import { BasketView } from '@basket/types';
import CreateBasketModal from '@basket/components/CreateBasketModal';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { isMobileOnly } from 'react-device-detect';

type NoSelectedBasketMessageProps = {
  title: string
  message: string
}

const NoSelectedBasketMessage = (
  {
    title,
    message,
  }: NoSelectedBasketMessageProps) => {
  const { t } = useTranslation();

  const [isCreateBasketModal, setIsCreateBasketModal] = useState<any>(false);

  const { changeView, createBasket, toggleBasket } = useReactBasket();

  return (
    <FlexBox
      width="100%"
      height="100%"
      position="relative"
      backgroundColor="#e8ecee"
    >
      {isMobileOnly && (
        <FlexBox
          position="absolute"
          top="5px"
          right="15px"
          height="40px"
          width="40px"
          alignItems="center"
          justifyContent="flex-end"
          fontSize="18px"
          color="#666f7d"
        >
          <FontAwesomeIcon
            color="inherit"
            onClick={toggleBasket}
            icon={faTimes}
            style={{
              minHeight: '18px',
            }}
          />
        </FlexBox>
      )}
      <FlexBox
        width="100%"
        maxWidth={isMobileOnly ? 'unset' : '400px'}
        margin="auto"
        padding={isMobileOnly ? '0 20px' : '0 10px'}
        flexDirection="column"
      >
        <FontAwesomeIcon
          icon={faShoppingCart}
          color="#84949f"
          style={{
            margin: '0 auto',
            minHeight: '45px',
            maxWidth: '45px',
          }}
        />
        <Text
          textAlign="center"
          mt="medium"
          fontSize="title"
          color="#84949f"
        >
          {title}
        </Text>
        <Text textAlign="center" color="#84949f" mt="small" mb="medium">{message}</Text>
        <Button
          label={t('create_new_basket')}
          onClick={() => setIsCreateBasketModal(true)}
        />
        <FlexBox
          p="15px 20px"
        >
          <FlexBox
            style={{
              borderBottom: '1px solid',
              borderColor: '#84949f',
              flexGrow: '1',
              margin: 'auto 0',
            }}
          />
          <Text
            p="0 10px"
          >
            {t('lbl_or')}
          </Text>
          <FlexBox
            style={{
              borderBottom: '1px solid',
              borderColor: '#84949f',
              flexGrow: '1',
              margin: 'auto 0',
            }}
          />
        </FlexBox>
        <Button
          label={t('lbl_show_baskets_list')}
          onClick={() => changeView(BasketView.BasketList)}

        />
      </FlexBox>
      {isCreateBasketModal && (
        <CreateBasketModal
          onApply={createBasket}
          onClose={() => setIsCreateBasketModal(false)}
        />
      )}
    </FlexBox>
  );
};

export default NoSelectedBasketMessage;
