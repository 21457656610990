import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

import { IDictionary } from '@ess/types';

import FlexBox from '@ess/ui/FlexBox';
import TextInput from '@ess/ui/Form/TextInput';

import { isMobileOnly } from 'react-device-detect';
import { Filters } from './Filters';
import { Sort } from './Sort';
import { BasketListActions } from './BasketListActions';

type ControlsProps = {
  onSearch: (name: string) => void
  onFilter: (params: IDictionary<any>) => void
  onSort: (value: string) => void
  onAction: (action: string, data: any) => void
  selectedBaskets: number[]
}

const Controls = ({
  onSearch, onSort, onFilter, onAction, selectedBaskets,
}: ControlsProps) => {
  const { t } = useTranslation();

  const onInputChange = (name: string) => {
    onSearch(name);
  };

  return (
    <FlexBox
      flexShrink={0}
      p="small"
      width="100%"
      alignItems="center"
      backgroundColor="white"
    >
      <FlexBox width="100%" alignItems="center" justifyContent="space-between">
        <FlexBox flexGrow={1}>
          <FlexBox width="100%" maxWidth={400} mr="small">
            <TextInput
              isClearable
              startIcon={<FontAwesomeIcon icon={faSearch}/>}
              onChange={(event) => onInputChange(event.target.value)}
              onClear={() => onInputChange('')}
              placeholder={t('lbl_search_basket')}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
            />
            <Filters onChange={onFilter}/>
          </FlexBox>
        </FlexBox>

        {!isMobileOnly && (
          <FlexBox width="100%" maxWidth={200} mr="small">
            <Sort onChange={onSort}/>
          </FlexBox>
        )}
        <BasketListActions selectedBaskets={selectedBaskets} onAction={onAction}/>
      </FlexBox>
    </FlexBox>
  );
};

export {
  Controls,
};
