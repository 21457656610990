import React from 'react';
import { useTranslation } from 'react-i18next';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Chip, { ChipGroup } from '@ess/ui/Chip';

type CustomOptionProps = {
  data: any;
  selected: any;
}

const CustomOption = ({ data, selected }: CustomOptionProps) => {
  const { t } = useTranslation();
  const priceDifference = Math.round(Number(data.extra.priceDifference.amount));
  const isIncludedInPrice = (
    !data?.extra?.priceDifference?.amount
    || data.extra.priceDifference.amount === '0'
    || data.value === selected.toString()
  );

  return (
    <FlexBox width="100%" alignItems="center">
      <FlexBox flexGrow={1} flexDirection="column" mr="small">
        <Text>{data.label}</Text>
        {data?.extra?.attributes?.length > 0 && (
          <FlexBox mt="tiny" mr="small">
            <ChipGroup>
              {data?.extra?.attributes?.map((item: string) => (
                <Chip key={item} label={data?.extra?.isReadyAttribute ? item : t(`lbl_${item}`)} variant="border"/>
              ))}
            </ChipGroup>
          </FlexBox>
        )}
      </FlexBox>
      <FlexBox flexShrink={0}>
        {isIncludedInPrice ? (
          <FlexBox>
            <Text fontWeight="bold">
              {t('lbl_included_in_price').toLowerCase()}
            </Text>
          </FlexBox>
        ) : (
          <FlexBox>
            <Text fontWeight="bold">
              {`${data.extra.priceDifference.sign} ${priceDifference} ${data.extra.priceDifference.currency}`}
            </Text>
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
};

const MultiRoomCustomOption = ({ data, selected }: CustomOptionProps) => {
  const { t } = useTranslation();
  return (
    <FlexBox width="100%" alignItems="center">
      <FlexBox flexGrow={1} flexDirection="column" mr="small">
        <Text>{data.label}</Text>
        {data?.extra?.attributes?.length > 0 && (
          <FlexBox mt="tiny" mr="small">
            <ChipGroup>
              {data?.extra?.attributes?.map((item: string) => (
                <Chip key={item} label={data?.extra?.isReadyAttribute ? item : t(`lbl_${item}`)} variant="border"/>
              ))}
            </ChipGroup>
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
};

export {
  CustomOption,
  MultiRoomCustomOption,
};
