import React, {
  CSSProperties, useCallback, useRef, useState,
} from 'react';
import { capitalize } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faRemove } from '@fortawesome/pro-solid-svg-icons';

import { useCFUpload } from '@ess/hooks/useCFUpload';

import { Button } from '@ess/ui/Button';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import TextInput from '@ess/ui/Form/TextInput';

import { IAddMediaRequest, MediaType } from '@basket/components/CustomMediaModal/types';
import { useReactBasket } from '@basket/hooks/useReactBasket';

import { showToast, TOAST_ERROR } from '@ess/utils';

import {
  ALLOWED_IMAGE_EXTENSIONS,
  ALLOWED_VIDEO_EXTENSIONS,
  UPLOAD_ERROR,
  UPLOAD_TIMEOUT,
} from '../constants';

const allowedExtensions = [...ALLOWED_IMAGE_EXTENSIONS, ...ALLOWED_VIDEO_EXTENSIONS];

type FileMethodProps = {
  onApply: ({ itemType, data }: IAddMediaRequest) => void
}

const fileInputStyles: CSSProperties = {
  opacity: 0,
  height: 0,
  visibility: 'hidden',
  position: 'absolute',
  left: '-9999px',
};

const getFileType = (file: File) => file.type.split('/')[0];

const FileMethod = ({ onApply }: FileMethodProps) => {
  const { t } = useTranslation();
  const { basket } = useReactBasket();
  const { upload, state } = useCFUpload();
  const [file, setFile] = useState<File>();
  const [mediaName, setMediaName] = useState<string>(t('lbl_custom_slide'));
  const [isDragging, setIsDragging] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);

  const isUploading = state === 'loading';

  const onFileInputChange = () => {
    const files = fileInput?.current?.files;

    if (files?.length) {
      setFile(files[0]);
    }
  };

  const onAddFileClickHandler = () => {
    fileInput?.current?.click();
  };

  const onDragEnterHandler = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDragging(true);
  };

  const onDragLeaveHandler = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDragging(false);
  };

  const onDragOverHandler = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const onDropHandler = (event: React.DragEvent) => {
    event.preventDefault();

    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      setIsDragging(false);
      setFile(file);
    }
  };

  const onApplyHandler = useCallback((url: string, itemType: MediaType) => {
    onApply({
      itemType,
      data: {
        Url: url,
        Name: mediaName,
      },
    });
  }, [file, mediaName]);

  const uploadFile = useCallback(() => {
    if (file) {
      (async () => {
        try {
          const formData = new FormData();
          const fileType = getFileType(file);
          const itemType = fileType === MediaType.Video
            ? MediaType.Video
            : MediaType.Image;

          formData.set('file', file);
          formData.set('fileType', `basket${capitalize(fileType)}`);

          const client = await upload({
            type: 'basketImage',
            content: file,
            additionalParams: {
              basketId: basket.current.id,
            },
          });

          if (client?.Sections?.File?.Url) {
            onApplyHandler(client.Sections.File.Url, itemType);
            return;
          }

          throw new Error('ErrorGeneral');
        } catch (error: any) {
          const errorCode = error?.response?.data?.errorCode;
          const message = errorCode && errorCode in UPLOAD_ERROR
            ? UPLOAD_ERROR[errorCode]
            : UPLOAD_ERROR.ErrorGeneral;

          showToast(TOAST_ERROR, t(message));
        }
      })();
    }
  }, [file, basket.current.id, onApplyHandler]);

  const removeFileHandler = () => {
    setFile(undefined);
  };

  const onMediaNameChange = (name: string) => {
    setMediaName(name);
  };

  return (
    <FlexBox
      p="large"
      minHeight={220}
      width="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      onDragEnter={onDragEnterHandler}
      onDragLeave={onDragLeaveHandler}
      onDragOver={onDragOverHandler}
      onDrop={onDropHandler}
    >
      {isDragging ? (
        <FlexBox
          position="relative"
          zIndex={10}
          width="100%"
          height="100%"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{
            pointerEvents: 'none',
          }}
        >
          <Text fontSize="32px">
            <FontAwesomeIcon icon={faCloudArrowUp} size="2xl"/>
          </Text>
          <Text mt="medium">{t('lbl_drop_file')}</Text>
        </FlexBox>
      ) : (
        <>
          <FlexBox
            mb="medium"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Text mb="tiny">
              {t('lbl_add_media_file_description')}
            </Text>
            <Text>
              {`${t('lbl_allowed_file_extensions')}: ${allowedExtensions.join(', ')}`}
            </Text>
            {file?.name && (
              <FlexBox width="100%">
                <FlexBox
                  my="small"
                  width="100%"
                  height={34}
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#f3f3f3"
                  borderRadius={4}
                  style={{
                    border: '1px solid #d3d3d3',
                  }}
                >
                  <Text
                    p="small"
                    fontWeight="bold"
                    whiteSpace="nowrap"
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '100%',
                    }}
                  >
                    {file.name}
                  </Text>
                  <FlexBox
                    alignItems="center"
                    justifyContent="center"
                    width={34}
                    ml="auto"
                    onClick={removeFileHandler}
                    style={{ cursor: 'pointer' }}
                  >
                    <Text color="errorRed" fontSize="12px">
                      <FontAwesomeIcon icon={faRemove}/>
                    </Text>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            )}
            {file && (
              <FlexBox width="100%">
                <TextInput
                  placeholder={t('lbl_media_name')}
                  value={mediaName}
                  autoFocus
                  onChange={(event) => onMediaNameChange(event.target.value)}
                  onClear={() => onMediaNameChange('')}
                  isClearable
                />
              </FlexBox>
            )}
          </FlexBox>

          <input
            ref={fileInput}
            type="file"
            accept={allowedExtensions.join(', ')}
            onChange={onFileInputChange}
            style={fileInputStyles}
          />

          {file ? (
            <Button
              size="large"
              label={t('lbl_add')}
              isLoading={isUploading}
              onClick={uploadFile}
              minWidth={100}
            />
          ) : (
            <Button
              label={t('lbl_choose_file')}
              size="large"
              onClick={onAddFileClickHandler}
            />
          )}
        </>
      )}

    </FlexBox>
  );
};

export {
  FileMethod,
};
