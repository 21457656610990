import styled from 'styled-components';
import { motion } from 'framer-motion';
import { darken } from 'polished';

/**
 * Drawer Base Styles
 */

const Drawer__Header = styled.div`
  display: flex;
  position: sticky;
  z-index: 99;
  top: 0;
  flex-shrink: 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  padding: 0 ${({ theme }) => theme.space.medium};
`;

const Drawer__Header__Inner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Drawer__Header__Title = styled.div`
  width: 100%;
  overflow: hidden;
  font-size: ${({ theme }) => theme.fontSizes.title};
`;

const Drawer__Header__CloseBtn = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-end;
  cursor: pointer;
  width: 40px;
  height: 40px;
  transition: all .1s ease-in;
  font-size: 18px;
`;

const Drawer__Content = styled.div`
  overflow: auto;
  flex-grow: 1;
  width: 100%;
`;

const Drawer__Controls = styled.div`
  display: flex;
  flex-shrink: 0;
  min-height: 50px;
  border-top: 1px solid #ddd;
`;

const Drawer = styled(motion.div)<{ zIndex?: number, isPortal?: boolean }>`
  display: flex;
  outline: 0;
  z-index: ${({ theme, zIndex }) => zIndex || theme.zIndex.modal};
  position: ${({ isPortal }) => (!isPortal ? 'relative' : 'fixed')};
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border-left: 1px solid ${({ theme }) => theme.colors.lighterGray};
  border-right: 1px solid ${({ theme }) => theme.colors.lighterGray};
  box-shadow: ${({ isPortal, theme }) => (isPortal ? theme.boxShadow[2] : undefined)};
  overflow: inherit;

  ${Drawer__Header__CloseBtn} {
    color: #666f7d;

    &:hover {
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;

export const Styled = {
  Drawer,
  Drawer__Header,
  Drawer__Header__Inner,
  Drawer__Header__Title,
  Drawer__Header__CloseBtn,
  Drawer__Content,
  Drawer__Controls,
};
