import Tooltip from '@ess/ui/Tooltip';
import FlexBox from '@ess/ui/FlexBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import Text from '@ess/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';

type HotelCategoryProps = {
  Accommodation: any
}

const HotelCategory = (
  {
    Accommodation,
  }: HotelCategoryProps,
) => {
  const { t } = useTranslation();
  return (
    <Tooltip content={t('hotel_category')}>
      <FlexBox
        width="28px"
        mr="small"
        justifyContent="center"
        alignItems="center"
      >
        <FontAwesomeIcon
          style={{
            marginBottom: isMobileOnly ? '2px' : '',
          }}
          icon={faStar}
          color="var(--text-color)"
          size="xs"
        />
        <Text ml="2px" fontSize={isMobileOnly ? '14px' : '12px'} fontWeight="bold" color="textColor">
          {Accommodation.Category.toFixed(1)}
        </Text>
      </FlexBox>
    </Tooltip>
  );
};
export default HotelCategory;
