import styled from 'styled-components';

import Text from '@ess/ui/Text';
import { isMobileOnly } from 'react-device-detect';

const Offer__Details = styled.div<{ transportOnly?: boolean }>`
  width: 100%;
  ${({ transportOnly }) => !transportOnly && 'border-top: 1px solid #e2e6e8;'};
`;

const Offer__Details__Content = styled.div`
  padding: ${({ theme }) => theme.space.small};
  font-size: 12px;
`;

const Offer__Detail = styled(Text)`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.tiny};

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => `${theme.mediaQueries.sm} {
    align-items: flex-start;
    flex-direction: column;
  }`}
`;

const Offer__Detail__Group = styled(Text)`
  font-size: 12px;
  color: #99a1ab;
  text-transform: uppercase;
  padding: ${({ theme }) => theme.space.small} 0;
  letter-spacing: 0.5px;
`;

const Offer__Detail__Label = styled(Text)`
  flex: 0 0 120px;
  font-size: 12px;

  ${({ theme }) => `${theme.mediaQueries.sm} {
    flex-basis: auto;
  }`}
`;

const Offer__Detail__Desc = styled(Text)`
  flex-grow: 1;
  font-size: 12px;
`;

const Offer__Inner = styled.div`
  display: flex;
  flex-wrap: initial;
  flex-flow: initial;
  align-items: center;
  min-height: 52px;
  width: 100%;
  padding: 7px 0;
  padding-left: 0;
`;

const Offer__Main = styled.div<{ isGroupedView: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: ${({ theme }) => theme.space.small};
  ${({ isGroupedView }) => (isGroupedView ? 'flex-grow: 1;min-width: 0;' : 'min-width: 230px;flex-shrink: 0')}
`;

const Offer__Main__Ungrouped = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-width: 0;
  padding-right: ${({ theme }) => theme.space.small};
`;

const Offer__Object = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
`;

const Offer__Object__Name = styled(Text)`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
  padding-right: ${({ theme }) => theme.space.medium};
`;

const Offer__Main__Top = styled.div`
  display: flex;
  flex-wrap: nowrap;
  max-width: 100%;
  height: 20px;
  align-items: center;
  padding-bottom: 2px;
`;

const Offer__Main__Bottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Offer__Operator = styled.div`
  display: flex;
  flex: 0 0 70px;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 ${({ theme }) => theme.space.small};
  font-weight: bold;

  ${({ theme }) => `${theme.mediaQueries.sm} {
     flex: 0 0 50px;
     padding: 0 ${theme.space.tiny};
  }`}
  img {
    max-width: 98%;
    max-height: 100%;
  }
`;

const Offer__Transport = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.space.tiny};
`;

const Offer__Transport__Desc = styled.div`
  margin-bottom: 0;
  margin-left: ${({ theme }) => theme.space.tiny};
  font-weight: bold;
  max-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Offer__Date = styled(Text)`
  white-space: nowrap;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.textAccentColor};
  font-weight: bold;
`;

const Offer__Duration = styled(Text)`
  white-space: nowrap;
  margin-bottom: 0;
  margin-left: 2px;
  color: ${({ theme }) => theme.colors.textSecondaryColor};
`;

const Offer__Price__Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const Offer__Price = styled.div<{ highlight: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 58px;

  ${({ theme }) => `${theme.mediaQueries.sm} {
    justify-content: right;
    width: 45px;
  }`}
`;

const Offer__Service = styled.div<{ disableGreyColor?: boolean }>`
  display: flex;
  align-items: center;
  overflow: hidden;
  color: ${({ theme, disableGreyColor }) => (disableGreyColor ? theme.colors.navy : theme.colors.textSecondaryColor)};
`;

const Offer__Service__Desc = styled(Text)`
  font-size: 12px;
  white-space: ${isMobileOnly ? 'wrap' : 'nowrap'};
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;

const Offer__Status = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45px;
  height: 100%;
`;

const Offer__Button = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 100%;
`;

const Offer__ControlsWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
`;

const Offer = styled.div<{ isSkeleton?: boolean, isSelected?: boolean, isMultiRoomMode?: boolean, isClickable?: boolean }>`
  width: 100%;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.radii['br-6']};
  background-color: ${({ theme }) => theme.colors.white};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  box-shadow: ${({ isSelected }) => (
    isSelected
      ? '0 0 0 1.5px #348cff'
      : 'rgba(0, 0, 0, 0.1) 0 1px 4px 0px'
  )};
  transition: all .1s ease-in;
  transition-property: box-shadow;
  align-items: center;

  ${({ isSkeleton }) => isSkeleton && `
    height: 52px;
    cursor: default;

    ${Offer__Inner} {
      height: 100%;
    }
  `}

  ${({ isMultiRoomMode }) => isMultiRoomMode && `
    align-items: flex-start;

    ${Offer__Main} {
      justify-content: flex-start;
    }

    ${Offer__Inner} {
      align-items: flex-start;
    }

    ${Offer__Main__Bottom} {
      flex-direction: column;
      justify-content: flex-start;
    }

    ${Offer__ControlsWrapper} {
      align-items: flex-start;
    }
  `}
`;

export const Styled = {
  Offer,
  Offer__Inner,
  Offer__Operator,
  Offer__Transport,
  Offer__Transport__Desc,
  Offer__Date,
  Offer__Duration,
  Offer__Price__Wrapper,
  Offer__Price,
  Offer__Main,
  Offer__Main__Top,
  Offer__Main__Bottom,
  Offer__Main__Ungrouped,
  Offer__Object,
  Offer__Object__Name,
  Offer__Service,
  Offer__Service__Desc,
  Offer__Status,
  Offer__Button,
  Offer__Details,
  Offer__Details__Content,
  Offer__Detail,
  Offer__Detail__Group,
  Offer__Detail__Desc,
  Offer__Detail__Label,
  Offer__ControlsWrapper,
};
