import React from 'react';
import { AnimatePresence } from 'framer-motion';

import { useDebouncedCallback, useThrottledCallback } from 'use-debounce';
import { Styled } from './DrawerOverlay.styles';

type DrawerOverlayProps = {
  onClick?: () => void
  zIndex: number
  show?: boolean;
  bodyScrollContainer?: string;
}

const DrawerOverlay = ({
  onClick = undefined,
  zIndex,
  show = false,
  bodyScrollContainer = 'body',
}: DrawerOverlayProps) => {
  const onWheel = (event: React.WheelEvent) => {
    if (!bodyScrollContainer) {
      return;
    }

    const scrollContainer = document.querySelector(bodyScrollContainer);

    if (scrollContainer) {
      const {
        scrollTop,
      } = scrollContainer;

      scrollContainer?.scrollTo({
        top: scrollTop + (event.deltaY > 0 ? 85 : -85),
        behavior: 'auto',
      });
    }
  };

  return (
    <AnimatePresence>
      <Styled.ModalOverlay
        show={show}
        onWheel={onWheel}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
        onClick={onClick}
        zIndex={zIndex - 1}
      />
    </AnimatePresence>

  );
};

export default DrawerOverlay;
